import React, { useState } from "react"
import PropTypes from "prop-types"

import logo from "../images/cobb_logo_rect.svg"
import Modal from "../components/modal"

// MUI
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import MoreIcon from "@material-ui/icons/MoreVert"
import CalendarToday from "@material-ui/icons/CalendarToday"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
    flexGrow: 1,
    marginTop: theme.spacing(5),
  },
  botton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}))

const Header = ({ siteTitle }) => {
  const classes = useStyles()
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)
  const [open, setOpen] = useState(false)

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget)
  }
  const mobileMenuId = "primary-search-account-menu-mobile"
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        className={classes.botton}
        component={Button}
        color="inherit"
        target="_blank"
        rel="noopener"
        href="https://outlook.office365.com/owa/calendar/AsesoriasMinerasCGBB@amcgbb.onmicrosoft.com/bookings/"
      >
        <IconButton color="inherit">
          <CalendarToday color="secondary"></CalendarToday>
        </IconButton>
        <p>Reserva</p>
      </MenuItem>
    </Menu>
  )

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar>
          <a href="https://amcgbb.cl" alt="CGBB Logo">
            <img src={logo} height={40} align="center" alt="logo" />
          </a>
          <div className={classes.root} />
          <div className={classes.sectionDesktop}>
            <Button
              className={classes.botton}
              color="inherit"
              target="_blank"
              rel="noopener"
              href="https://outlook.office365.com/owa/calendar/AsesoriasMinerasCGBB@amcgbb.onmicrosoft.com/bookings/"
            >
              Reserva
            </Button>
            <Button href="#contacto" color="inherit" variant="outlined">
              Contacto
            </Button>
          </div>
          <div className={classes.sectionMobile}>
            <Button href="/#contacto" color="inherit" variant="outlined">
              Contacto
            </Button>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      <Modal open={open} setOpen={setOpen} />
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
